import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { exchangeCalenderOAuthCode } from '../../../lib/api';

interface CalendarOAuthParams {
  code: string;
  state: string;
}

const CalenderOAuthRedirect = () => {
  const location = useLocation();
  const history = useHistory();
 

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Extract and assign values, ensuring they conform to CalendarOAuthParams
    const calendarParams: CalendarOAuthParams = {
      state: queryParams.get('state') || '',
      code: queryParams.get('code') || '',
    };
    if (calendarParams.state && calendarParams.code) {

      exchangeCalenderOAuthCode(calendarParams.code, calendarParams.state)
        .then((response: any) => {
          if (response)
            notifyOpener('connected', response);
        })
        .catch((error) => {
          let errorMessage = 'Failed to connect to calendar.';
          if (error.response) {
            errorMessage = error.response.data.message || errorMessage;
          } else if (error.request) {
            errorMessage = 'No response received from the server.';
          }
          notifyOpener('error', errorMessage);
        })
        .finally(() => {
          window.close(); // Close the popup window
        });
    } else {
      console.error('State or code is missing.');
      notifyOpener('error', 'State or code is missing. Please try again.');
      // window.close();
    }


  }, [location, history]);

  const notifyOpener = (status: string, message: string): void => {
    if (window.opener) {
      window.opener.postMessage({ status, message }, window.location.origin);
    } else {
      console.log('No opener window available. You can store the message or handle it in another way.');
      alert(message); // For example, show an alert in the popup window
    }
  };


  return <div>Connecting to your calendar... Please wait.</div>; // Loading UI
};

export default CalenderOAuthRedirect;
